require("@rails/ujs").start()

const jquery = require("jquery")

require("./question/app")
require("./nps/app")
require("./login/app")
require("./notification/content")
require("./notification/comment")
require("./menu/menu")

require("./likes/actions")

require("jquery-validation/dist/jquery.validate")
require("jquery-validation/dist/localization/messages_pt_BR")

import 'bootstrap'

window.jQuery = jquery;
window.$ = jquery;

$(function () {
  let locale = $('html').attr('lang')
  if (locale == 'mx') {
    require("jquery-validation/dist/localization/messages_es")
  } else if (locale == 'pt') {
    require("jquery-validation/dist/localization/messages_pt_PT")
  } else {
    require("jquery-validation/dist/localization/messages_pt_BR")
    $.extend($.validator.messages, {
      required: "Este campo &eacute; obrigatório.",
    });
  }
});
